import uFuzzy from "@leeoniya/ufuzzy";

export function SanitizeString(name: string) : string {
  return uFuzzy.latinize(name).replace('\'', ' ').replace('`', ' ').replace('œ', 'oe').toLowerCase();
}

export function IsAcceptableDistance(name: string, guess: string) : boolean {
  const found = DistanceSanitize(guess);
  const needle = DistanceSanitize(name);
  let tolerance = 2;
  if (found.length > 7) {
    tolerance = 8;
  }
  return Math.abs(found.length - needle.length) <= tolerance;
}

function DistanceSanitize(name:string) : string {
return name.replace('-', '').replace('les ', '').replace('la ', '').replace('le ', '')
.replace('l\'', '').replace('s', '');
}

const uFuzzyOptions: uFuzzy.Options = {
  intraMode: 0
};
const fuzzyInstance = new uFuzzy(uFuzzyOptions);

export function searchHayStackUtil(hayStack: string[], needle: string) : string | null {
  const searchIdxs = fuzzyInstance.filter(hayStack, needle);
  if (searchIdxs?.length === 1) {
    return hayStack[searchIdxs![0]];
  }
  return null;
};
