import React, { createContext, useContext, useState } from "react";

type ResetContextType = { 
  reset: boolean; 
  triggerReset: () => void; 
};

const ResetContext = createContext<ResetContextType>({ reset: false, triggerReset: () => {} });

export const ResetProvider = ({ children }: { children: React.ReactNode }) => {
  const [reset, setReset] = useState(false);

  const triggerReset = () => {
    setReset(true);
    // Reset the flag on next tick so that the effect in MapComponent can be re-triggered later.
    setTimeout(() => setReset(false), 0);
  };

  return (
    <ResetContext.Provider value={{ reset, triggerReset }}>
      {children}
    </ResetContext.Provider>
  );
};

export const useReset = () => useContext(ResetContext);
