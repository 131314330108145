import { useRef, useEffect, useState } from "react";
import mapboxGL, { Map } from "mapbox-gl";
import { Commune } from "../commune-interface";
import { useReset } from "../context/ResetContext";
import IconButton from '@mui/material/IconButton';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';

mapboxGL.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

export default function MapComponent({ communes }: { communes: Commune[] }) {
  const isMobile = window.innerWidth <= 768;
  const mapContainer = useRef(null);
  const mapRef = useRef<Map | null>(null);
  const originalLng = -61;
  const originalLat = 14.6;
  const originalZoom = isMobile ? 9.24 : 10.8; // Adjust zoom for mobile
  const [lng, setLng] = useState(originalLng);
  const [lat, setLat] = useState(originalLat);
  const [zoom, setZoom] = useState(originalZoom);
  const { reset } = useReset();

  // Add the recenter function
  const recenterMap = () => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [originalLng, originalLat],
        zoom: originalZoom,
      });
    }
  };

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    if (!mapRef.current) {
      const map = new Map({
        container: mapContainer!.current!,
        style: "mapbox://styles/fhocs/clnxdzgt6002i01qxew2l0wm3",
        center: [originalLng, originalLat],
        zoom: originalZoom,
        minZoom: 5,
      });

      map.on("move", () => {
        setLng(Number(map.getCenter().lng.toFixed(4)));
        setLat(Number(map.getCenter().lat.toFixed(4)));
        setZoom(Number(map.getZoom().toFixed(2)));
      });

      map.on("style.load", () => {
        communes.forEach((commune) => {
          map.addSource(commune.code.toString(), {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: commune.zone.type,
                    coordinates: commune.zone.coordinates,
                  },
                  properties: {
                    name: commune.name,
                  },
                },
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: commune.mairiePos.coordinates,
                  },
                  properties: {
                    name: `Mairie de ${commune.name}`,
                  },
                },
              ],
            },
          });

          map.addLayer({
            id: `Contour de ${commune.code}`,
            type: "line",
            source: commune.code.toString(),
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#877b59",
              "line-width": 1,
            },
            filter: ["==", "$type", "Polygon"],
          });

          map.addLayer({
            id: `Fill de ${commune.code}`,
            type: "fill",
            source: commune.code.toString(),
            paint: {
              "fill-color": "#5cb85c",
              "fill-opacity": 0.2,
            },
            layout: {
              visibility: !!commune.timeFound ? "visible" : "none",
            },
            filter: ["==", "$type", "Polygon"],
          });

          map.addLayer({
            id: `Mairie de ${commune.code}`,
            type: "symbol",
            source: commune.code.toString(),
            layout: {
              visibility: !!commune.timeFound ? "visible" : "none",
              "icon-image": "town-hall", // reference the image
              "icon-size": 1,
              "text-field": commune.name,
              "text-variable-anchor": ["top", "bottom", "left", "right"],
              "text-radial-offset": 0.5,
              "text-justify": "auto",
            },
            filter: ["==", "$type", "Point"],
          });
        });
      });
      mapRef.current = map;
    }
    const foundCommunes = communes.filter((c) => !!c.timeFound);
    foundCommunes.forEach((commune) => {
      mapRef.current?.setLayoutProperty(
        `Fill de ${commune.code}`,
        "visibility",
        "visible"
      );
      mapRef.current?.setLayoutProperty(
        `Mairie de ${commune.code}`,
        "visibility",
        "visible"
      );
    });
  }, [communes]);

  useEffect(() => {
    if (reset && mapRef.current) {
      communes.forEach((commune) => {
        mapRef.current?.setLayoutProperty(
          `Fill de ${commune.code}`,
          "visibility",
          "none"
        );
        mapRef.current?.setLayoutProperty(
          `Mairie de ${commune.code}`,
          "visibility",
          "none"
        );
      });
    }
  }, [reset, communes]);

  return (
    <div>
      <div className="map-container-canvas" ref={mapContainer}></div>
      <div className="map-overlay">
        <IconButton onClick={recenterMap} aria-label="RecenterMap">
          <MyLocationTwoToneIcon />
        </IconButton>
      </div>
    </div>
  );
}
